import React from 'react'
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Layout from "../layout/layout"
import SEO from "../seo/seo"
import OfferCard from "../components/offerCard"

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!, $serviceSlug: String!) {
    contentfulWhatWeOffer (categories: {elemMatch: {serviceSlug: {eq: $serviceSlug}}}, slug: {eq: $slug}) {
      offerTitle
      slug
      categories {
        serviceId
        serviceName
        serviceDescription {
          childMarkdownRemark {
            html
          }
        }
        serviceSlug
        serviceDescriptionSub {
          childMarkdownRemark {
            html
          }
        }
        serviceImages {
          fluid (maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
          description
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 50
  }
}));

const ViewServiceTemplate = (props) => {
  const classes = useStyles();
  const offerTitle = props.data.contentfulWhatWeOffer.offerTitle
  const services = props.data.contentfulWhatWeOffer.categories
  const siteMetadata = props.data.site.siteMetadata
  const { serviceSlug } = props.pageContext

  return (
    <Layout>
      <SEO 
        title= {`${serviceSlug} | ${siteMetadata.title}`}
      />
      {
        services.map(service => (
          service.serviceSlug === serviceSlug && (
            <Container 
              key={service.serviceId} 
              wrap="wrap" 
              className={classes.root}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" my={3}>
                    <Link to="/services">
                      <Typography
                        variant="h3"
                        color="textPrimary"
                      >
                        {offerTitle}
                      </Typography>
                    </Link>
                    <Typography variant="h3">
                      {'  > ' + service.serviceName}
                    </Typography>
                  </Box>
                  {
                    service.serviceDescription && (
                      <Typography 
                        variant="body1" 
                        component="div"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.serviceDescription.childMarkdownRemark.html,
                          }}
                        />
                      </Typography>
                    )
                  }
                </Grid>
                {
                  // console.log(service.serviceImages)
                  service.serviceImages && service.serviceImages.map(serviceImage => (
                    <Grid 
                      item 
                      key={serviceImage.title} 
                      md={4} xl={4} 
                      xs={12} 
                      sm={6}
                    >
                      <OfferCard 
                        offerTitle={serviceImage.title}
                        offerDescription={serviceImage.description}
                        offerImage={serviceImage} 
                      />
                    </Grid>
                  ))
                }
                <Grid item xs={12}>
                  {
                    service.serviceDescriptionSub && (
                      <Typography variant="body1" component="div">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: service.serviceDescriptionSub.childMarkdownRemark.html,
                          }}
                        />
                      </Typography>
                    )
                  }
                </Grid>
              </Grid>
            </Container>      
          )
        ))
      }
    </Layout>
  )
}

export default ViewServiceTemplate