import React from 'react';
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Modal from "./modal"

const useStyles = makeStyles({
  root: {
    boxShadow: "0 5px 10px 0 rgba(0,0,0,0.1),0 3px 10px 0 rgba(0,0,0,0.10) !important",
    backgroundColor: '#f3f3f3',
    borderRadius: 0
  },
  offerDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, /* number of lines to show */
    WebkitBoxOrient: 'vertical',
  }
});

const OfferCard = ({ offerTitle, offerDescription, offerImage, offerSlug }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea 
          onClick={!offerSlug ? handleOpen : handleClose}
        >
          <Link 
            to={ !offerSlug ? '' : offerSlug} 
            color="textPrimary"
          >
            {offerImage && (
              <Img fluid={offerImage.fluid} alt={offerImage.title} style={{height: 250, minHeight: 120}} />
            )}
            <CardContent>
              <Typography 
                gutterBottom 
                variant="h6" 
                color="textPrimary"
                component="p" 
                align="center"
              >
                {offerTitle}
              </Typography>
              <Typography 
                variant="body2" 
                component="p" 
                align="center" 
                className={classes.offerDescription}
              >
                {offerDescription}
              </Typography>
            </CardContent>
          </Link>
        </CardActionArea>
      </Card>
      <Modal 
        open={isOpen}
        handleClose={handleClose}
        title={offerTitle}
        description={offerDescription}
        image={offerImage}
      />
    </>
  );
}

OfferCard.propTypes = {
  offerTitle: PropTypes.string,
  offerDescription: PropTypes.string,
  // offerImage: PropTypes.bool,
}

OfferCard.defaultProps = {
  offerTitle: ``,
  offerDescription: ``,
  // offerImage: false,
}

export default OfferCard