import React from 'react';

import Img from "gatsby-image"

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      // flexDirection: "row-reverse",
      width: 320
    },
    [theme.breakpoints.up('sm')]: {
      // flexDirection: "row-reverse",
      width: 520
    },
    [theme.breakpoints.up('md')]: {
      // flexDirection: "row-reverse",
      width: 800
    }
  }
}));

export default function TransitionsModal({
  open,
  handleClose,
  title,
  description,
  image
}) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Img fluid={image.fluid} alt={title} className={classes.image} />
            <h2 id="transition-modal-title">{title}</h2>
            <p id="transition-modal-description">{description}</p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}